import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/about/Hero";
import Masonry from "../sections/about/Masonry";
import Content from "../sections/about/Content";
import CTA from '../sections/home/CTA';
import Stats from "../sections/about/Stats";

const PricingPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          footerStyle: 'style4'
        }}
      >
        <Hero />
        <Masonry />
        <Content />
        <Stats />
        <CTA className="bg-images"/>
      </PageWrapper>
    </>
  );
};
export default PricingPage;
