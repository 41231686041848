import React from "react";
import { Link } from "gatsby";
import backgroundImage from '../../assets/image/cta/ocorie-ft-img.jpg';

const Content = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={className}
        {...rest}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div
                className="text-center pt-14 pb-14 py-lg-22 dark-mode-texts"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <h2 className="font-size-11 mb-7">Apply now</h2>
                <p className="font-size-5 line-height-28 px-md-10 px-lg-16 px-xl-25 mb-0">
                  Join the community of Advanced Information Security Experts
                </p>
                <Link
                  to={'/contact#contact-form'}
                  className="btn btn-dodger-blue-2 rounded-5 mt-12"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
