import React from 'react';

import imgC from '../../assets/image/about/ocorie-abt-splash-img-1.png';
import imgDP from '../../assets/image/dots/dot-pattern-black.png';

const Content = () => {
  return (
    <div className="pt-10 pt-md-13 pt-lg-14 pb-10 pb-md-13 pb-lg-14 mb-lg-1">
      <div className="container">
        <div className="row justify-content-center">
          {/* Right */}
          <div className="col-xl-5 col-lg-5 col-md-5 col-xs-10">
            <div className="about-content-image-group-1 mb-8 mb-lg-0">
              <div className="img-1">
                <img
                  className="w-100 rounded-10"
                  src={imgC}
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration={300}
                />
                <div className="img-2 z-index-n1 mt-n13">
                  <img
                    className="w-100"
                    src={imgDP}
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration={300}
                    data-aos-delay={100}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 col-md-7 col-xs-10">
            <div
              className="mx-lg-14 mx-xl-14"
              data-aos="fade-left"
              data-aos-duration={300}
            >
              <div className="">
                <p className="font-size-8 font-weight-medium heading-default-color heading-default-color">Vision</p>
                <p className="justify-text font-size-7 line-height-25 mb-7 mr-xs-17 mr-md-11 mr-lg-18 mr-xl-20">
                  To become the go to experts in Information Security Training
                  in Ghana
                </p>
              </div>
              <div className="">
                <p className="font-size-8 font-weight-medium heading-default-color">Mission</p>
                <p className="justify-text font-size-7 line-height-25 mb-7 mr-xs-17 mr-md-11 mr-lg-18 mr-xl-20">
                  Our mission is to deliver information security training
                  through several delivery methods -live &amp; virtual,
                  classroom-style, or at the client's workplace.
                </p>
              </div>
            </div>
          </div>
          {/* Left End */}
        </div>
      </div>
    </div>
  );
};

export default Content;
